const repertoryEquipmentFormVO = () => {
  return {
    deviceInfo: {
      id: '',
      // 设备类型
      category: {
        id: ''
      },
      // 部门
      department: {
        id: ''
      },
      // 仓库
      depot: {
        id: ''
      },
      facility: {
        id: ''
      },
      area: {
        id: ''
      },
      deviceCondition: 'WELL',
      location: '',
      reason: '', // WELL DAMAGE
      memo: '',   // 备注
      model: '', //设备型号
      name: '',
      no: '', // 设备编号
      transferType: 'RK', // RK CK ZY BD
      type: 'ADD', // ADD:新增入库 ONLINE:在线设备入库
      vendor: '', // 生产厂家
      orgId: 0,
      // technology: {
      //   id: ''
      // }
    },
    // 入库原因
    reason: '',
    // 入库日期
    time: '',
    // 上传图片id
    fileIds: [],
    imageIds: [],
    files: [],
    images: [],
    user: {
      id: ''
    }
  }
}

export default repertoryEquipmentFormVO