<template>
  <a-form :form="form" >
    <a-form-item label="设备类型名称"
                 :label-col="questionLabelCol"
                 :wrapper-col="questionWrapperCol">
      <a-input v-decorator="['name', {rules: [{ required: true, message: '设备名称不能为空' }]}]" placeholder="请输入"></a-input>
    </a-form-item>
    <a-form-item label="设置父节点"
                 :label-col="questionLabelCol"
                 :wrapper-col="questionWrapperCol">
      <a-tree-select
        :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }"
        :treeData="treeData"
        placeholder="请选择设备类型"
        treeDefaultExpandAll
        v-decorator="['parentId']"
        :allowClear="true"
      ></a-tree-select>
    </a-form-item>
    <a-form-item label="排序"
                 :label-col="questionLabelCol"
                 :wrapper-col="questionWrapperCol">
      <a-input-number :min="1" v-decorator="['sorter', {rules: [{ required: true, message: '排序不能为空' }]}]" placeholder="请输入"></a-input-number>
    </a-form-item>
  </a-form>
</template>

<script>
  import typeFormVO from './common/typeFormVO'
  import SERVICE_URLS from '@/api/service.url'
  export default {
    props: {
      treeData: {
        type: Array,
        required: true
      },
      sup_this: {
        type: Object,
        required: true
      },
      type: {
        type: String,
        default: 'edit'
      }
    },
    name: 'typeForm',
    data () {
      return {
        questionLabelCol: { span: 6 },
        questionWrapperCol: { span: 17 },
        formItem: typeFormVO(),
        form: this.$form.createForm(this),
        parentId: undefined,
      }
    },
    methods: {
      getParentId(key) {
        this.parentId = key.toString()
      },
      // 把form取到的值 赋给创建的formItem
      setVOFields(values) {
        this.formItem.parentId = values.parentId
        this.formItem.name = values.name
        this.formItem.sorter = values.sorter
      },
      submitAdd () {
        this.form.validateFields(
          (err, values) => {
            if (!err) {
              this.setVOFields(values)
              this.$http(this, {
                url: SERVICE_URLS.equipment2.equipmentTypeApi.create,
                data: this.formItem,
                noTips: true,
                success: (data) => {
                  //添加成功，清空表单数据，并触发回调
                  //清空表单数据
                  this.formItem = typeFormVO()
                  this.form.resetFields()
                  this.$emit('addSuccess', data.body)
                },
                error: () => {
                  this.$emit('addError')
                }
              })
            }
          })
      },
      submitEdit () {
        this.form.validateFields(
          (err, values) => {
            if (!err) {
              this.setVOFields(values)
              this.$http(this, {
                url: SERVICE_URLS.equipment2.equipmentTypeApi.update,
                data: this.formItem,
                noTips: true,
                params: {
                  id: this.formItem.id
                },
                success: (data) => {
                  //清空表单数据
                  this.formItem = typeFormVO()
                  this.form.resetFields()
                  this.$emit('editSuccess', data.body)
                }
              })
            }
          }
        )
      },
      loadData(id) {
        this.$http(this, {
          url: SERVICE_URLS.equipment2.equipmentTypeApi.view,
          params: {
            id: id
          },
          noTips: true,
          success: (data) => {
            this.setConfigFields(data.body)
          }
        })
      },
      // 给form赋值
      setConfigFields(data) {
        this.formItem = {
          id: data.id,
          name: data.name,
          parentId: data.parentId,
          sorter: data.sorter
        }
        if (this.formItem.parentId) {
          console.log(this.formItem.parentId)
          this.parentId = this.formItem.parentId.toString()
          this.form.setFieldsValue({
            parentId: this.formItem.parentId.toString(),
            name: this.formItem.name,
            sorter: this.formItem.sorter
          })
        } else {
          this.form.setFieldsValue({
            parentId: null,
            name: this.formItem.name,
            sorter: this.formItem.sorter
          })
        }
      },
      onChange(key) {
        this.parentId = key.toString();
      }
    }
  }
</script>

<style scoped>
  .dynamic-delete-button {
    cursor: pointer;
    position: relative;
    top: 4px;
    font-size: 24px;
    color: #999;
    transition: all .3s;
  }

  .dynamic-delete-button:hover {
    color: #777;
  }

  .dynamic-delete-button[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }
</style>