<template>
  <a-drawer :title="title" placement="right" width="700" @close="onClose" :visible="visible" :mask="false">
    <div class="details">
      <div class="details-title">
        <div class="details-title-text">基础信息</div>
        <i class="details-title-text-i"></i>
      </div>
      <a-row class="details-content-rowBasicInfo">
        <a-col :span="12"><span>设备名称：</span>{{ deviceInfo.name || '-' }}</a-col>
        <a-col :span="12"><span>设备编号：</span>{{ deviceInfo.no || '-' }}</a-col>
        <a-col :span="12"
        ><span>设备类型：</span> <span v-if="deviceInfo.category">{{ deviceInfo.category.name || '-' }}</span></a-col
        >
        <a-col :span="12"><span>设备型号：</span>{{ deviceInfo.model || '-' }}</a-col>
        <a-col :span="12"><span>生产厂家：</span>{{ deviceInfo.vendor || '-' }}</a-col>
        <a-col :span="12"
        ><span>所属部门：</span
        ><span v-if="recentTransferInfo.department">{{ recentTransferInfo.department.name || '-' }}</span></a-col
        >
        <a-col :span="12"
        ><span>{{
            recentTransferInfo.type === 'RK'
              ? '入库人：'
              : recentTransferInfo.type === 'CK'
              ? '出  库  人：'
              : recentTransferInfo.type === 'ZY'
              ? '转  移  人：'
              : '调  拨   人：'
          }}</span>
          <span v-if="recentTransferInfo.user">{{ recentTransferInfo.user.name }}</span></a-col
        >
        <a-col :span="12"
        ><span>设备状态：</span>
          <a-tag
            class="details-content-rowBasicInfo-tag"
            color="#1890ff"
            v-if="recentTransferInfo.deviceCondition === 'WELL'"
          >良好
          </a-tag
          >
          <a-tag
            class="details-content-rowBasicInfo-tag"
            color="#ff0000"
            v-if="recentTransferInfo.deviceCondition === 'DAMAGE'"
          >损坏
          </a-tag
          >
        </a-col>
        <a-col :span="12"
        ><span>{{ location }}：</span><span v-if="recentTransferInfo.area"> {{ recentTransferInfo.area.name }}</span
        ><span v-if="title !== '在线设备详情' && deviceInfo.depot">{{ deviceInfo.depot.name }} </span
        ><span>{{ recentTransferInfo.location }}</span>
          <span v-if="recentTransferInfo.latitude && recentTransferInfo.longitude">
            ({{ recentTransferInfo.latitude || '-' }} {{ recentTransferInfo.longitude || '-' }})
          </span>
        </a-col>
        <a-col :span="12"
        ><span>{{
            recentTransferInfo.type === 'RK'
              ? '入库时间：'
              : recentTransferInfo.type === 'CK'
              ? '出库时间：'
              : recentTransferInfo.type === 'ZY'
              ? '转移时间：'
              : '调拨时间：'
          }}</span
        >{{ moment(recentTransferInfo.time).format('YYYY-MM-DD') || '-' }}
        </a-col
        >
        <a-col :span="24" v-if="recentTransferInfo.type === 'CK'"><span>所属设施：</span>{{ recentTransferInfo.facility?recentTransferInfo.facility.name : '-' }}</a-col>
        <a-col :span="24"
        ><span>{{
            recentTransferInfo.type === 'RK'
              ? '入库原因：'
              : recentTransferInfo.type === 'CK'
              ? '出库原因：'
              : recentTransferInfo.type === 'ZY'
              ? '转移原因：'
              : '调拨原因：'
          }}</span
        >{{ recentTransferInfo.reason || '-' }}
        </a-col
        >
      </a-row>
      <div class="details-title">
        <div class="details-title-text">出入库记录</div>
        <i class="details-title-text-i"></i>
      </div>
      <div class="details-content-rests">
        <a-table
          :scroll="{ x: 800 }"
          size="small"
          :pagination="false"
          bordered
          :rowKey="(record) => record.id"
          :columns="equipmentLookColums"
          :dataSource="tableData"
        >
          <template slot="_index" slot-scope="text, record, index">
            {{ index + 1 }}
          </template>
          <template slot="dateCreated" slot-scope="dateCreated">
            {{ moment(dateCreated).format('YYYY-MM-DD') }}
          </template>
          <template slot="deviceCondition" slot-scope="deviceCondition">
            <a-tag color="#1890ff" v-if="deviceCondition === 'WELL'">良好</a-tag>
            <a-tag color="#ff0000" v-if="deviceCondition === 'DAMAGE'">损坏</a-tag>
          </template>
          <template slot="type" slot-scope="type">
            <span v-if="type === 'RK'">入库</span>
            <span v-if="type === 'CK'">出库</span>
            <span v-if="type === 'ZY'">转移</span>
            <span v-if="type === 'DB'">调拨</span>
          </template>
        </a-table>
      </div>
      <div class="details-title">
        <div class="details-title-text">图片</div>
        <i class="details-title-text-i"></i>
      </div>
      <div class="details-content-rests">
        <template v-if="images.length">
          <qiniu-image-upload ref="qiniuImageRef" type="view" :items="images"/>
        </template>
        <template v-else>
          <div class="details-content-rests-noImageState">
            <a-icon class="details-content-rests-noImageState-icon" type="smile"/>
            <span>暂未上传图片</span>
          </div>
        </template>
      </div>
      <div class="details-title">
        <div class="details-title-text">文件</div>
        <i class="details-title-text-i"></i>
      </div>
      <div class="details-content-rests">
        <template v-if="files.length">
          <qiniu-file-upload ref="qiniuFileRef" type="view" :items="files"></qiniu-file-upload>
        </template>
        <template v-else>
          <div class="details-content-rests-noImageState">
            <a-icon class="details-content-rests-noImageState-icon" type="smile"/>
            <span>暂未上传文件</span>
          </div>
        </template>
      </div>
    </div>
  </a-drawer>
</template>
<script>
  import SERVICE_URLS from '@/api/service.url'
  import { equipmentLookColums } from './common/common'
  import QiniuImageUpload from '@/components/QiniuUpload/QiniuImageUpload'
  import moment from 'moment'
  import QiniuFileUpload from '@/components/QiniuUpload/QiniuFileUpload'

  export default {
    name: 'lookEquipment',
    components: { QiniuImageUpload, QiniuFileUpload },
    data () {
      return {
        ifSub: true,
        visible: false,
        equipmentLookColums: equipmentLookColums(),
        data: {},
        recentTransferInfo: {},
        deviceInfo: {},
        tableData: [],
        images: [],
        files: [],
        sceneImages: [],
        title: '',
        location: ''
      }
    },
    methods: {
      moment,
      onClose () {
        this.visible = false
      },
      getEquipmentInfo (id, type) {
        this.data = {}
        this.recentTransferInfo = {}
        this.deviceInfo = {}
        this.tableData = []
        this.images = []
        this.title = ''
        if (type === 'isZX') {
          this.title = '在线设备详情'
          this.location = '安装位置'
        } else {
          this.title = '库存设备详情'
          this.location = '所属仓库'
        }
        this.$http(this, {
          url: SERVICE_URLS.equipment2.equipmentInfoApi.detail,
          params: {
            id: id
          },
          noTips: true,
          success: (data) => {
            this.$nextTick(() => {
              this.data = data.body
              this.deviceInfo = data.body.deviceInfo
              this.recentTransferInfo = data.body.recentTransferInfo
              this.tableData = data.body.transferList
              this.images = data.body.images
              this.files = data.body.files
              this.sceneImages = data.body.sceneImages
            })
          }
        })
      }
    }
  }
</script>
<style scoped></style>
