<template>
  <a-spin :spinning="ifSub">
    <a-form :form="form" layout="vertical">
      <a-row :gutter="16">
        <a-col :span="8">
          <a-form-item label="设备名称">
            <a-input
              v-decorator="['deviceInfo.name', { rules: [{ required: true, message: '请填写设备名称' }] }]"
              placeholder="请输入设备名称"
            ></a-input>
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item label="设备编号">
            <a-input
              v-decorator="['deviceInfo.no', { rules: [{ required: true, message: '请填写设备编号' }] }]"
              placeholder="请输入设备编号"
            ></a-input>
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item label="设备类型">
            <a-tree-select
              :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }"
              :treeData="treeData"
              allowClear
              placeholder="请选择设备类型"
              treeDefaultExpandAll
              v-decorator="['deviceInfo.category.id', { rules: [{ required: true, message: '请选择设备类型' }] }]"
            ></a-tree-select>
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item label="设备型号">
            <a-input v-decorator="['deviceInfo.model']" placeholder="请输入设备型号"></a-input>
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item label="设备生产厂家">
            <a-input v-decorator="['deviceInfo.vendor']" placeholder="请输入生成厂家"></a-input>
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item label="所属部门" v-show="menuType !=='zx'">
            <a-tree-select :disabled="isEdit" :treeData="deptList" @change="selectDept" v-decorator="['deviceInfo.department.id',{rules: [{ required: true, message: '请选择部门' }],trigger:'change'}]">
            </a-tree-select>
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item label="设备存放仓库" v-show="menuType !=='zx'">
            <a-select
              placeholder="请选择仓库"
              :disabled="isEdit"
              v-decorator="['deviceInfo.depot.id', { rules: [{ required: true, message: '请选择设备存放仓库' }] }]"
            >
              <a-select-option v-for="item in depotsList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item label="设备状态" v-show="menuType !=='zx'">
            <a-select
              :disabled="isEdit"
              placeholder="请选择状态"
              v-decorator="[
          'deviceInfo.deviceCondition',
          { initialValue: 'WELL', rules: [{ required: true, message: '请选择设备状态' }] },
        ]"
            >
              <a-select-option v-for="item in deviceConditionList" :key="item.value" :value="item.value">{{
                item.name
                }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item label="设备入库日期" v-show="menuType !=='zx'">
            <a-date-picker
              :disabled="isEdit"
              style="width: 100%;"
              v-decorator="[
          'time',
          { initialValue: moment(new Date(), 'YYYY-MM-DD'), rules: [{ required: true, message: '请选择时间' }] },
        ]"
              placeholder="请选择入库日期"
            ></a-date-picker>
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item label="设备入库人" v-show="menuType !=='zx'">
            <a-select disabled v-decorator="['user.id', { rules: [{ required: true, message: '请选择巡检人' }] }]">
              <a-select-option v-for="item in equipmentPutDepotUserList" :key="item.id" :value="item.id">{{
                item.name
                }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item label="入库原因" v-show="menuType !=='zx'">
            <a-input :disabled="isEdit" v-decorator="['reason']" placeholder="请输入入库原因"></a-input>
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item label="备注信息">
            <a-input v-decorator="['deviceInfo.memo']" placeholder="请输入备注信息"></a-input>
          </a-form-item>
        </a-col>
        <a-col :span="8" v-if="menuType !=='kc'">
          <a-form-item label="所属设施">
            <a-select v-decorator="['deviceInfo.facility.id']" placeholder="请选择设施" allowClear>
              <a-select-option v-for="item in facilityList" :key="item.id" :value="item.id">{{item.name}}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item label="安装位置" v-if="menuType !=='kc'">
            <a-tree-select style="width: 100px"
                           :treeData="areaList"
                           showSearch
                           placeholder="省市"
                           allowClear
                           treeDefaultExpandAll
                           v-decorator="['deviceInfo.area.id']"
            ></a-tree-select>
            <a-input v-decorator="['deviceInfo.location']" placeholder="请输入具体地址" style="width: 150px;margin-left: 5px;"></a-input>
          </a-form-item>
        </a-col>
        <a-col :span="24">
          <a-form-item style="width: 100%;">
            <qiniu-image-upload
              ref="qiniuImageRef"
              @getImageData="getImageData"
              :document-dir-id="1"
              :multiSelection="true"
              type="edit"
              :items="formItem.images"
            ></qiniu-image-upload>
          </a-form-item>
        </a-col>
        <a-col :span="24">
          <a-form-item style="width: 100%;">
            <qiniu-file-upload
              ref="qiniuFileRef"
              @getFileData="getFileData"
              :document-dir-id="2"
              :multiSelection="true"
              type="edit"
              :items="formItem.files"
            ></qiniu-file-upload>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-spin>
</template>

<script>
  import repertoryEquipmentFormVO from './common/repertoryEquipmentFormVO'
  import { deviceCondition, transferType, type } from './common/common'
  import SERVICE_URLS from '@/api/service.url'
  import moment from 'moment'
  import QiniuImageUpload from '../../../../components/QiniuUpload/QiniuImageUpload'
  import entityCURDCallback from '../../../common/mixins/entityCURDCallback'
  import QiniuFileUpload from '@/components/QiniuUpload/QiniuFileUpload'
  import Vue from 'vue'
  // import entityCRUDWithCopy from '@/views/common/mixins/entityCRUDWithCopy'
  export default {
    components: { QiniuImageUpload, QiniuFileUpload },
    mixins: [entityCURDCallback],
    props: {
      categoryId: {
        type: [String, Number],
        required: true
      },
      menuType: {
        type: String
      }
    },
    name: 'testForm',
    data () {
      return {
        entityBaseUrl: SERVICE_URLS.equipment2.equipmentInfoApi,
        initFormItem: repertoryEquipmentFormVO(),
        questionLabelCol: { span: 10 },
        questionWrapperCol: { span: 20 },
        equipmentPutDepotUserList: [],
        deviceConditionList: deviceCondition(),
        transferTypeList: transferType(),
        typeList: type(),
        depotsList: [],
        deptList: [],
        isEdit: false,
        equipmentTypeId: this.categoryId,
        showFormItem: false,
        treeData: [],
        areaList: [],
        facilityList: []
      }
    },
    watch: {
      categoryId: function (newVal) {
        this.equipmentTypeId = newVal
        this.form.setFieldsValue({
          'deviceInfo.category.id': newVal.toString()
        })
      }
    },
    created () {
      this.loadCheckUserList()
      this.loadUser()
      this.loadDept()
      this.getTree()
      this.loadAreaList()
    },
    methods: {
      moment,
      onChange (key) {
        this.equipmentTypeId = key
      },
      getTree () {
        this.$http(this, {
          url: SERVICE_URLS.equipment2.equipmentTypeApi.search,
          noTips: true,
          success: (data) => {
            this.treeData.push(data.body)
          }
        })
      },
      loadAreaList () {
        this.$http(this, {
          url: SERVICE_URLS.equipment2.equipmentInfoApi.areaTree,
          noTips: true,
          success: (data) => {
            this.areaList = data.body
          }
        })
      },
      getFacilityList () {
        this.$http(this, {
          url: SERVICE_URLS.equipment2.facilityApi.selectFacility,
          noTips: true,
          data: { departmentId: this.formItem.recentTransferInfo.department.id },
          success: (data) => {
            this.facilityList = data.body
          }
        })
      },
      // 页面加载初始化表单
      loadFunction () {
        this.selectDept(this.currentDeptId().organizationId)
        this.form.setFieldsValue({
          'deviceInfo.category.id': this.categoryId,
          'deviceInfo.department.id': this.currentDeptId().organizationId
        })
        this.loadUser()
      },
      loadUser () {
        this.$nextTick(function () {
          this.form.setFieldsValue({
            'user.id': this.$store.getters.currentUser.id
          })
        })
      },
      loadDept () {
        this.$http(this, {
          url: SERVICE_URLS.organization.antTree,
          noTips: true,
          success: (data) => {
            this.deptList = data.body
          }
        })
      },
      loadCheckUserList () {
        this.$http(this, {
          url: SERVICE_URLS.user.userOrgJob.orgUser,
          noTips: true,
          success: (data) => {
            this.equipmentPutDepotUserList = data.body
          }
        })
      },
      selectDept (value) {
        this.$http(this, {
          url: SERVICE_URLS.equipment2.equipmentInfoApi.selectDepots,
          noTips: true,
          data: {
            departmentId: value
          },
          success: (data) => {
            this.depotsList = data.body
          }
        })
      },
      addSuccessCallback () {
        this.loadUser()
      },
      addCopySuccessCallback () {
        this.loadUser()
      },
      setVOFields (values) {
        console.log(this.formItem, values)
        Object.assign(this.formItem, values)
        console.log('end formItem', this.formItem)
        Vue.set(this.formItem.deviceInfo, 'transferType', 'RK')
        Vue.set(this.formItem.deviceInfo, 'type', 'ADD')
        // type: 'ADD', // ADD:新增入库 ONLINE:在线设备入库)
        // if (values.deviceInfo.facility.id === undefined) {
        //   this.formItem.deviceInfo.facility = {
        //     id: ''
        //   }
        // }


      },
      submitEdit () {
        this.form.validateFields((err, values) => {
          if (!err) {
            const id = this.formItem.deviceInfo.id
            this.setVOFields(values)
            this.$http(this, {
              url: SERVICE_URLS.equipment2.equipmentInfoApi.update,
              data: this.formItem,
              params: {
                id: id
              },
              success: (data) => {
                this.formItem = repertoryEquipmentFormVO()
                this.$emit('editSuccess', data.body)
              },
              error: () => {
                this.$emit('editError', 'httpError')
              }
            })
          }
        })
      },
      loadData (id) {
        this.$http(this, {
          url: SERVICE_URLS.equipment2.equipmentInfoApi.view,
          params: {
            id: id
          },
          noTips: true,
          success: (data) => {
            if (this.menuType === 'zx') {
              this.isEdit = true
            } else {
              if (data.body.isEdit) {
                this.isEdit = false
              } else {
                this.isEdit = true
              }
            }
            this.selectDept(data.body.deviceInfo.department.id)
            this.setFields(data.body)
          }
        })
      },
      setFields (data) {
        Object.assign(this.formItem, data)
        this.getFacilityList()
        /*图片初始化展示处理赋值Ids*/
        data.images.forEach((item) => {
          this.formItem.imageIds.push(item.id)
        })
        /*文件初始化展示处理赋值Ids*/
        data.files.forEach((item) => {
          this.formItem.fileIds.push(item.id)
        })
        // this.equipmentTypeId = this.formItem.deviceInfo.deviceCondition
        this.form.setFieldsValue({
          'deviceInfo.deviceCondition': this.formItem.deviceInfo.deviceCondition,
          'deviceInfo.depot.id': this.formItem.deviceInfo.depot.id,
          'deviceInfo.category.id': this.formItem.deviceInfo.category.id.toString(),
          'deviceInfo.name': this.formItem.deviceInfo.name,
          'deviceInfo.memo': this.formItem.deviceInfo.memo,
          'deviceInfo.model': this.formItem.deviceInfo.model,
          'deviceInfo.no': this.formItem.deviceInfo.no,
          'deviceInfo.vendor': this.formItem.deviceInfo.vendor,
          'deviceInfo.department.id': this.formItem.deviceInfo.department.id,
          time: this.formItem.recentTransferInfo.time,
          'user.id': this.formItem.recentTransferInfo.user.id,
          reason: this.formItem.recentTransferInfo.reason,
          'deviceInfo.facility.id': this.formItem.recentTransferInfo.facility ? this.formItem.recentTransferInfo.facility.id : undefined,
          'deviceInfo.area.id': this.formItem.recentTransferInfo.area ? this.formItem.recentTransferInfo.area.id : undefined,
          'deviceInfo.location': this.formItem.recentTransferInfo.location ? this.formItem.recentTransferInfo.location : undefined
        })
        // if (this.formItem.deviceInfo.category.id !== undefined) {
        //   this.equipmentTypeId = this.formItem.deviceInfo.category.id.toString()
        // }
        // this.deviceConditionId = this.formItem.deviceInfo.deviceCondition
      },
      /*上传图片回调方法*/
      getImageData (data) {
        this.formItem.imageIds = []
        data.forEach((item) => {
          this.formItem.imageIds.push(item.id)
        })
      },
      /*上传文件回调方法*/
      getFileData (data) {
        this.formItem.fileIds = []
        data.forEach((item) => {
          this.formItem.fileIds.push(item.id)
        })
      }
    }
  }
</script>

<style scoped>
  /*.ant-row {*/
  /*  width: 30%;*/
  /*  float: left;*/
  /*  margin-left: 3%;*/
  /*}*/
</style>
