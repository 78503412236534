export const equipmentLookColums = () => {
  return [
    {
      title: '序号',
      width: 50,
      align: 'center',
      fixed: 'left',
      scopedSlots: { customRender: '_index' }
    },
    {
      title: '操作类型',
      width: 100,
      dataIndex: 'type',
      key: 'type',
      align: 'center',
      fixed: 'left',
      scopedSlots: { customRender: 'type' }
    },
    {
      title: '操作人',
      width: 100,
      align: 'center',
      dataIndex: 'user.name',
    },
    {
      title: '操作时间',
      width: 100,
      align: 'center',
      dataIndex: 'dateCreated',
      scopedSlots: { customRender: 'dateCreated' }
    },
    {
      title: '所属地点',
      width: 150,
      align: 'center',
      ellipsis: true,
      customRender: (text, record) => {
          const time = `${record.depot ? record.depot.name : ''}  ${record.area ? record.area.name : ''} ${record.location ? record.location : ''}`;//es6写法
          return time;
        }
    },
    {
      title: '原因',
      width: 200,
      align: 'center',
      dataIndex: 'reason',
      ellipsis: true,
      scopedSlots: { customRender: 'reason' }
    },
    {
      title: '状态',
      align: 'center',
      dataIndex: 'deviceCondition',
      fixed: 'right',
      width: 100,
      scopedSlots: { customRender: 'deviceCondition' }
    }
  ]
}

export const metricsType = () => {
  return [
    { value: 'yc', name: '遥测' },
    { value: 'yx', name: '遥信' },
    { value: 'yt', name: '遥调' },
    { value: 'yk', name: '遥控' },
  ]
}
export const deviceCondition = () => {
  return [
    { value: 'WELL', name: '良好' },
    { value: 'DAMAGE', name: '损坏' },
  ]
}
export const transferType = () => {
  return [
    { value: 'RK', name: '入库' },
    { value: 'CK', name: '出库' },
    { value: 'ZY', name: '转移' },
    { value: 'DB', name: '调拨' },
  ]
}
export const type = () => {
  return [
    { value: 'ADD', name: '新增入库' },
    { value: 'ONLINE', name: '在线设备入库' },
  ]
}
